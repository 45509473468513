<template>
  <div class="main">
    <Navbar></Navbar>
    <div style="flex: 1">
      <router-view/>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Navbar,
    Footer
  }
}
</script>
<style scoped>
.main{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
</style>