<template>
	<div class="nav_bg">
		<div class="nav">
			<div class="nav_logo">
				<img src="@/assets/img/logo.png" alt="" />
			</div>
			<div class="menu">
				<div v-for="(item, m_index) in menu" :key="m_index" class="menu_item" :class="{ menu_item_on: item.state }" @click="menuClick(m_index)">
					<span>
						{{$t(`nav.${item.name}`)}}
					</span>
				</div>
			</div>
			<div class="i18n">
				<span @click="setI18n('click', 'zh')" class="i18n_item" :class="{'i18n_no': i18n_id == 'zh'}">中文</span>
				<span>/</span>
				<span @click="setI18n('click', 'en')" class="i18n_item" :class="{'i18n_no': i18n_id == 'en'}">EN</span>
			</div>
			<div @click="drawer = !drawer" class="menu_m">
				<i class="el-icon-s-fold"></i>
			</div>
		</div>
		<div v-if="menu_id !== 0" class="view_title_bg">
			<div class="view_title">{{view_title}}</div>
		</div>
		<el-drawer
			title="我是标题"
			size="50%"
			:visible.sync="drawer"
			:with-header="false">
			<div @click="drawer = false" class="menu_dr">
				<div v-for="(item, m_index) in menu" :key="m_index" class="menu_dr_item" :class="{ menu_dr_item_on: item.state }" @click="menuClick(m_index)">
					<span>
						{{$t(`nav.${item.name}`)}}
					</span>
				</div>
			</div>
			<div class="i18n_dr">
				<span @click="setI18n('click', 'zh')" class="i18n_item" :class="{'i18n_no': i18n_id == 'zh'}">中文</span>
				<span>/</span>
				<span @click="setI18n('click', 'en')" class="i18n_item" :class="{'i18n_no': i18n_id == 'en'}">EN</span>
			</div>
		</el-drawer>
	</div>
</template>
<script>
export default {
	data() {
		return {
			menu: [
				{ name: 'homePage', path: '/', state: true, id: 0},
				{ name: 'aboutUs', path: '/about', state: false, id: 1 },
				{ name: 'product', path: '/product', state: false, id: 2 },
				{ name: 'compantNews', path: '/newsList', state: false, id: 3 },
				{ name: 'hire', path: '/hire', state: false, id: 4 },
				{ name: 'contact', path: '/contact', state: false, id: 5 }
			],
			menu_id: 0,
			view_title_val: '',
			view_title: '',
			i18n_id: 'zh',
			drawer: false
		}
	},
	watch: {
		$route(to, from) {
			this.setMenu(to.path)
		}
	},
	created() {
		this.setMenu(this.$route.path)
		if (localStorage.getItem('i18n')) {
			this.setI18n('created', localStorage.getItem('i18n'))
		}
	},
	methods: {
		menuClick(index) {
			if (this.$route.path == this.menu[index].path) {
				return
			}
			this.$router.push(this.menu[index].path)
		},
		setMenu(path) { // 设置菜单选中事件
			if (path == '/news') {
				path = '/newsList'
			}
			this.menu.forEach(item => {
				item.state = false
				if (item.path == path) {
					this.menu_id = item.id
					this.view_title_val = item.name
					this.view_title = this.$t(`navTitle.${item.name}`)
					item.state = true
				}
			})
		},
		setI18n (type, id) {
			localStorage.setItem('i18n', id)
			this.i18n_id = id
			this.$i18n.locale = id
			this.view_title = this.$t(`navTitle.${this.view_title_val}`)
			if (type === 'click') {
				this.$router.go(0)
				this.drawer = false
			}
		}
	}
}
</script>
<style scoped>
.nav_bg {
	width: 100%;
}
.nav {
	display: flex;
	align-items: center;
	margin: 0 auto;
	box-sizing: border-box;
}
.nav_logo {
	height: 85px;
}
.nav_logo img{
	width: auto;
	height: 100%;
}
.menu {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}
.menu_item {
	margin: 0 15px;
	text-align: center;
	font-size: 16px;
	color: #666666;
	cursor: pointer;
	height: 100px;
}
.menu_item span {
	display: inline-block;
	height: 100%;
	line-height: 90px;
	border-top: 8px solid transparent;
	box-sizing: border-box;
}
.menu_item span:hover {
	color: #14a6a3;
}
.menu_item_on span {
	border-color: #ff6600;
	color: #14a6a3;
}
.i18n{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	width: 80px;
	height: 40px;
	border: 1px solid #f4f4f4;
	font-size: 14px;
	border-radius: 4px;
}
.i18n_item{
	margin: 0 4px;
	cursor: pointer;
}
.i18n_no{
	color: #14a6a3;
}
.menu_m{
	display: none;
}
.view_title_bg{
	display: flex;
	align-items: center;
	width: 100%;
	height: 100px;
	background-image: url('../assets/img/ny_beijing_di.png');
}
.view_title{
	position: relative;
	margin: 0 auto;
	padding-left: 20px;
	font-size: 26px;
	color: #fff;
	box-sizing: border-box;
}
.view_title::after{
	content: '';
	position: absolute;
	left: 0;
	top: 1px;
	width: 4px;
	height: 100%;
	background-color: #ff6600;
	border-radius: 3px;
}
@media screen and (min-width: 1000px) {
	.nav {
		width: 1000px;
		height: 100px;
	}
	.view_title{
		width: 1000px;
	}
	.menu_item{
		min-width: 80px;
	}
}
@media screen and (max-width: 1000px) {
	.menu_item{
		display: none;
		height: 70px;
	}
	.menu_item span{
		line-height: 55px;
	}
	.menu_item:nth-child(1){
		display: block;
	}
	.menu_item:nth-child(2){
		display: block;
	}
	.menu_item:nth-child(3){
		display: block;
	}
	.nav_logo{
		margin-left: 20px;
		height: 60px;
	}
	.view_title_bg{
		height: 80px;
	}
	.view_title{
		padding-left: 40px;
		width: 100%;
		font-size: 23px;
	}
	.view_title::after{
		left: 20px;
	}
	.i18n{
		display: none;
	}
	.menu_m{
		margin-top: 0px;
		display: block;
		line-height: 40px;
		width: 40px;
		font-size: 30px;
		text-align: center;
		cursor: pointer;
		color: #666666;
	}
	.menu_dr_item{
		line-height: 55px;
		border-bottom: 1px solid #eee;
		cursor: pointer;
	}
	.menu_dr_item span{
		padding-left: 6px;
		border-left: 4px solid transparent;
	}
	.menu_dr_item_on span{
		border-color: #ff6600;
		color: #14a6a3;
	}
	.i18n_dr{
		line-height: 55px;
		border-bottom: 1px solid #eee;
	}
	.i18n_dr .i18n_item{
		margin: 0 10px;
		padding: 5px 0;
	}
}
@media screen and (max-width: 500px) {
	.nav_logo{
		margin-left: 15px;
		height: 45px;
	}
	.menu_item{
		margin: 0 7px;
		font-size: 14px;
		height: 50px;
	}
	.menu_item span{
		line-height: 44px;
		border-width: 4px;
	}
	.menu_m{
		padding: 0 5px;
		font-size: 30px;
		text-align: center;
		cursor: pointer;
	}
	.view_title_bg{
		height: 60px;
	}
	.view_title{
		font-size: 18px;
	}
	.menu_m{
		width: 35px;
		line-height: 35px;
		font-size: 25px;
	}
}
</style>
